const {getUrlParameter} = require("./custom");

jQuery(document).ready(function(){
    //FAQ Dropdowns
    //==================
    jQuery(".accordion__item--answer-container").hide();

    jQuery(".accordion__item").click(function(){
        jQuery(".accordion__item--answer-container").slideUp("slow");
        jQuery(".faq-svg").removeClass("svg-rotate");
        if(jQuery(this).find(".accordion__item--answer-container")[0].style.display === 'block') {
            jQuery(this).find(".accordion__item--answer-container").slideUp("slow");
            jQuery(this).find(".faq-svg").removeClass("svg-rotate");
        } else if (jQuery(this).find(".accordion__item--answer-container")[0].style.display === 'none') {
            jQuery(this).find(".accordion__item--answer-container").slideDown("slow");
            jQuery(this).find(".faq-svg").addClass("svg-rotate");
        }
    });

    function goToFaqElement(param) {
        let faqParamID = '#' + param;
        $('html, body').animate({scrollTop: $(faqParamID).offset().top - 120},500,'linear');
        $(faqParamID).click();
    }

    let faqParam = getUrlParameter('faq');

    if (faqParam) {
        goToFaqElement(faqParam);
    }

 })
