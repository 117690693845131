/* ==========================================================================
   Avoid 'console' errors in browsers that lack a console.
   ========================================================================== */
   (function() { var method; var noop = function () {}; var methods = [ 'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn']; var length = methods.length; var console = (window.console = window.console || {}); while (length--) { method = methods[length]; if (!console[method]) { console[method] = noop; } } }());


   jQuery(document).ready(function($) {
       // let mainNav = document.getElementsByClassName('siteHeader');
       // mainNav.addEventListener('scroll',() =>
       //     mainNav.scrollTop = Math.max(1, Math.min(mainNav.scrollTop,
       //         mainNav.scrollHeight - mainNav.clientHeight - 1))
       // );

       // Menu plus icons
       $('#menu-header-nav .menu-item-has-children ul, #menu-main-menu-mobile .menu-item-has-children ul').siblings('a').addClass('hasChildren').before('<a class="plus" href="#" aria-label="Expand Sub-menu" />');

       $('.plus').on("click", function(e){
           var plus = $(this);
           plus.toggleClass('plusOpen').siblings('ul').slideToggle('fast');
           plus.parent('li').toggleClass('open');
           plus.siblings('.hasChildren').toggleClass('childrenborder');
           e.preventDefault();
       });

       // Mobile menu toggle
       $('.menuToggle').on('click',function(e){
           $('.siteNavigation').slideToggle('fast');
           $(this).toggleClass('close');
           e.preventDefault();
       });

       // Open links with rel="external" in new window
       $('a[rel="external"]').on("click",function(e){
           window.open($(this).attr('href'));
           e.preventDefault();
       });

       $("#gforms_reset_css-css").remove();
       $("#gforms_formsmain_css-css").remove();
       $("#gforms_ready_class_css-css").remove();
       $("#gforms_browsers_css-css").remove();
       // $("#wpadminbar").css('display', 'none');

       // Page background parallax
       if($('.pageHeader').length) {
           var bg = $('.pageHeader').css('background-image');
               bg = bg.replace('url(','').replace(')','').replace(/\"/gi, "");

           $('.pageHeader').css('background', 'none').parallax({
               imageSrc: bg,
               speed: 0.4
           });
       }

   });

   $('#video').videoPopup();
