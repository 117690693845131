export function getUrlParameter(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

jQuery( document ).ready(function($) {

    if(window.location.href.split('/')[3] !== 'tindall-advantage') {

    }

    let screenSize = $(window).width();
    let navContainerSize = $('.headerContainer').outerHeight();
    let infoBoxWidth = $('.single-market__header--infobox').outerWidth();

    jQuery('#search-nav').on('click', function (e) {
        jQuery('#search-field').toggleClass("hide");
    });

    let mapHeight = $('#svgContainer').outerHeight();
    let svgZoomPosition = Math.floor(mapHeight * .5);
    $('.svg-zoom').css('top', svgZoomPosition);

    if (screenSize >= 1025) {
        $('.header, .site-content').css('marginTop', navContainerSize)
        $('.adjust-width-for-infobox').css('maxWidth', `calc(100% - ${infoBoxWidth}px )`)
    } else {
      $('.header').css('marginTop', 0);
    }
    $(window).resize(function () {
        screenSize = $(window).width();
        let newNavContainerSize = $('.headerContainer').outerHeight();
        let newInfoBoxWidth = $('.single-market__header--infobox').outerWidth();
        if (screenSize >= 1025) {
            $('.header, .site-content').css('marginTop', newNavContainerSize);
            $('.adjust-width-for-infobox').css('maxWidth', `calc(100% - ${infoBoxWidth}px )`);
        } else {
            $('.header, .site-content').css('marginTop', 0);
            $('.adjust-width-for-infobox').css('maxWidth', '100%');
        }

        let mapHeight = $('#svgContainer').outerHeight();
        let svgZoomPosition = Math.floor(mapHeight * .5);
        $('.svg-zoom').css('top', svgZoomPosition);
    })

    if (screenSize <= 920) {
        $(".split-content__left-content").removeClass('text-right');
    }

  $('#popup-video').videoPopup({
    autoplay: 1,
    controlsColor: 'white',
    showVideoInformations: 0,
    width: 1000,
  });

  setTimeout(function() {
      let headerTopPadding = parseInt($('.header-type__text-header').css('paddingTop'), 10);
      let heroHeight = $('.header__hero-container').height();
      let headerContentHeight = $('.header__content').outerHeight(true);
      let halfHeroHeight = heroHeight / 2;
      $('.header').css('marginBottom', halfHeroHeight);
      $('.header-type__text-header').css('maxHeight', halfHeroHeight + headerContentHeight + headerTopPadding);
  }, 500);

  let leftImage = document.querySelectorAll('.split-content__left-content .image-left');
  if (leftImage.length > 0) {
      $(leftImage).parents('.split-content').addClass('has-image-left');
  }
  let rightImage = document.querySelectorAll('.split-content__right-content .image-right');
    if (rightImage.length > 0) {
        $(rightImage).parents('.split-content').addClass('has-image-right');
    }

    $('.resources__bifurcation-filter__item--input-container .checkmark').click(function() {
        $(this).siblings('input').click();
    })


    // Newsletter
    let multiSelectPlaceholder = jQuery('#field_2_7 label').html();
    jQuery('#input_2_7').attr('data-placeholder', multiSelectPlaceholder)


    // Truncated text
    $('.truncate-container .button').click(function() {
        $(this).hide();
        $(this).parents('.truncate-container').find('.truncate').addClass('remove-truncate');
    })
    function open_location_modal(name) {
        $('#' + name + '-modal').addClass('modalOpened');
        $('.location-modals').show();
        $('html').css('overflow', 'hidden');
    }
    function close_location_modal() {
        $('html').css('overflow', 'auto');
        $('.location-modals').hide();
        $('.location-modal').removeClass('modalOpened');
    }

    $(document).click(function(e) {
        if( $(e.target).is('.location-modals__backdrop') ) {
        close_location_modal();
        }
    });

    //Wrap Email and Phone fields in a div so grid.css can be used.
    $('li.gf_email_phone').wrapAll('<div class="email-phone-container" />');

});
