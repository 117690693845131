jQuery(document).ready(function($) {
    let fullWidthGalleryContainers = $('.full-width-content__gallery-container');
    let fullWidthGallerySliderContainers = $('.full-width-content__gallery-slider');
    let fullWidthGallerySliders = [];
    if (fullWidthGallerySliderContainers.length) {
        for (let i = 0; i < fullWidthGallerySliderContainers.length; i++) {
            fullWidthGallerySliderContainers[i].classList.add('full-width-content__gallery-slider-' + [i]);
            fullWidthGalleryContainers[i].classList.add('full-width-content__gallery-container-' + [i]);
            jQuery(".full-width-content__gallery-slider-" + [i]).slick({
                slidesToShow: 3,
                slidesToScroll: 3,
                variableWidth: false,
                autoplay: false,
                controls: false,
                dots: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.full-width-content__gallery-container-' + [i] + ' .controls .prev').click((el) => {
                fullWidthGallerySliders[i].goTo('prev');
            })
            $('.full-width-content__gallery-container-' + [i] + ' .controls .next').click(() => {
                fullWidthGallerySliders[i].goTo('next');
            })
        }
    }

    //Full Width Card Slider on contact page vs homepage
    if ($('#contact-locations').length) {
        let contactLocationsSlider = $('.full-width-content .slider__card-slider');
        for (let i = 0; i < contactLocationsSlider.length; i++) {
            contactLocationsSlider[i].classList.add('slider__card-slider-' + [i]);
            jQuery(".slider__card-slider-" + [i]).slick({
                adaptiveHeight: false,
                variableWidth: false,
                autoplaySpeed: 6000,
                infinite: false,
                slidesToShow: 4.5,
                slidesToScroll: 1,
                arrows: false,
                prevArrow: false,
                nextArrow: false,
                dots: true,
                autoplay: false,
                pauseOnHover: true,
                responsive: [
                    {
                        breakpoint: 1450,
                        settings: {
                            slidesToShow: 3.5,
                            slidesToScroll: 1,
                            dots: true,
                        }
                    },
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesToShow: 2.5,
                            slidesToScroll: 1,
                            dots: true,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1.5,
                            slidesToScroll: 1,
                            dots: true,
                        }
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });
        }
    } else {

        let fullWidthCardSlider = $('.full-width-content .slider__card-slider');
        if (fullWidthCardSlider.length) {
            for (let i = 0; i < fullWidthCardSlider.length; i++) {
                fullWidthCardSlider[i].classList.add('slider__card-slider-' + [i]);
                jQuery(".slider__card-slider-" + [i]).slick({
                    adaptiveHeight: false,
                    variableWidth: false,
                    autoplaySpeed: 6000,
                    infinite: false,
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    arrows: false,
                    prevArrow: false,
                    nextArrow: false,
                    dots: true,
                    autoplay: false,
                    pauseOnHover: true,
                    responsive: [
                        {
                            breakpoint: 1000,
                            settings: {
                                slidesToShow: 2.5,
                            }
                        },
                        {
                            breakpoint: 769,
                            settings: {
                                slidesToShow: 1.5,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: true,
                            }
                        }
                        // You can unslick at a given breakpoint now by adding:
                        // settings: "unslick"
                        // instead of a settings object
                    ]
                });
            }
        }
    }

    let testimonialSlider = $('.slider__testimonials');
    if (testimonialSlider.length) {
        for (let i = 0; i < testimonialSlider.length; i++) {
            testimonialSlider[i].classList.add('slider__testimonials-' + [i]);
            jQuery(".slider__testimonials-" + [i]).slick({
                adaptiveHeight: false,
                variableWidth: false,
                autoplaySpeed: 6000,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                prevArrow: false,
                nextArrow: false,
                dots: true,
                autoplay: false,
                pauseOnHover: true,
            });
        }
    }

    let splitTestimonialsSlider = $('.slider__split-testimonials');
    if (splitTestimonialsSlider.length) {
        for (let i = 0; i < splitTestimonialsSlider.length; i++) {
            splitTestimonialsSlider[i].classList.add('slider__split-testimonials-' + [i]);
            jQuery(".slider__split-testimonials-" + [i]).slick({
                adaptiveHeight: false,
                variableWidth: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                prevArrow: true,
                nextArrow: true,
                dots: false,
                autoplay: false,
                pauseOnHover: true,
                responsive: [
                    {
                        breakpoint: 560,
                        settings: {
                            dots: true,
                        }
                    }
                ]
            });
            $('.split-testimonial-arrows__back').click((el) => {
                $(".slider__split-testimonials-" + [i]).slick('slickPrev');
            })
            $('.split-testimonial-arrows__forward').click(() => {
                $(".slider__split-testimonials-" + [i]).slick('slickNext');
            })
        }
    }


    let imageSlider = $('.slider__images');
    if (imageSlider.length) {
        for (let i = 0; i < imageSlider.length; i++) {
            imageSlider[i].classList.add('slider__images-' + [i]);
            let slidesShownDesktop = parseFloat($('.slider__images-' + [i]).attr('data-slides-shown-desktop'));
            jQuery(".slider__images-" + [i]).slick({
                adaptiveHeight: false,
                variableWidth: false,
                autoplaySpeed: 6000,
                infinite: false,
                slidesToShow: slidesShownDesktop ? slidesShownDesktop : 4.5,
                slidesToScroll: 1,
                arrows: false,
                prevArrow: false,
                nextArrow: false,
                dots: true,
                autoplay: false,
                pauseOnHover: true,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 4.5,
                            slidesToScroll: 1,
                            dots: true,
                        }
                    },
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            dots: true,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                        }
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });
        }
    }

    let videoSlider = $('.slider__videos');
    if (videoSlider.length) {
        for (let i = 0; i < videoSlider.length; i++) {
            videoSlider[i].classList.add('slider__videos-' + [i]);
            let slidesShownDesktop = parseFloat($('.slider__videos-' + [i]).attr('data-slides-shown-desktop'));
            jQuery(".slider__videos-" + [i]).slick({
                adaptiveHeight: false,
                variableWidth: false,
                infinite: true,
                slidesToShow: slidesShownDesktop,
                slidesToScroll: 1,
                arrows: false,
                prevArrow: true,
                nextArrow: true,
                dots: false,
                autoplay: false,
                pauseOnHover: true,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: slidesShownDesktop-1,
                            slidesToScroll: 1,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                        }
                    }
                ]
            });
            $('.video-slider-arrows .video-slider-arrows__back').click((el) => {
                $(".slider__videos-" + [i]).slick('slickPrev');
            })
            $('.video-slider-arrows .video-slider-arrows__forward').click(() => {
                $(".slider__videos-" + [i]).slick('slickNext');
            })
        }
    }

    let headerSlider = $('.header__slider');
    if (headerSlider.length) {
        for (let i = 0; i < headerSlider.length; i++) {
            headerSlider[i].classList.add('header__slider-' + [i]);
            jQuery(".header__slider-" + [i]).slick({
                adaptiveHeight: false,
                variableWidth: false,
                autoplaySpeed: 6000,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                prevArrow: false,
                nextArrow: false,
                dots: true,
                autoplay: true,
                pauseOnHover: true,
            });
        }
    }

    let featuredBlogsSlider = $('.slider__featured-blogs');
    if (featuredBlogsSlider.length) {
        for (let i = 0; i < featuredBlogsSlider.length; i++) {
            featuredBlogsSlider[i].classList.add('slider__featured-blogs-' + [i]);
            jQuery(".slider__featured-blogs-" + [i]).slick({
                adaptiveHeight: false,
                variableWidth: false,
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: false,
                dots: true,
                autoplay: false,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 2.5,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: false,
                        }
                    }
                ]
            });

            $('.featured-blogs-arrows .featured-blogs-arrows__back').click(() => {
                $(".slider__featured-blogs-" + [i]).slick('slickPrev');
            })
            $('.featured-blogs-arrows .featured-blogs-arrows__forward').click(() => {
                $(".slider__featured-blogs-" + [i]).slick('slickNext');
            })
        }
    }

	let galleryContainers = $('.split-content__gallery-container');
    let gallerySliderContainers = $('.split-content__gallery-slider');
    let gallerySliders = [];
    if (gallerySliderContainers.length) {
        for (let i = 0; i < gallerySliderContainers.length; i++) {
            gallerySliderContainers[i].classList.add('split-content__gallery-slider-' + [i]);
            galleryContainers[i].classList.add('split-content__gallery-container-' + [i]);
            gallerySliders[i] = tns({
                container: '.split-content__gallery-slider-' + [i],
                items: 1,
                slideBy: 1,
                // gutter: 20,
                autoplay: false,
                controls: false,
                nav: false,
                autoplayButton: false,
                autoplayButtonOutput: false,
            });

            $('.split-content__gallery-container-' + [i] + ' .controls .prev').click((el) => {
                gallerySliders[i].goTo('prev');
            })
            $('.split-content__gallery-container-' + [i] + ' .controls .next').click(() => {
                gallerySliders[i].goTo('next');
            })
        }
    }

    let featuredProjectsSlider = $('.our-work-slider__slideshow-container');
    if (featuredProjectsSlider.length) {
        for (let i = 0; i < featuredProjectsSlider.length; i++) {
            featuredProjectsSlider[i].classList.add('our-work-slider__slideshow-container-' + [i]);
            jQuery(".our-work-slider__slideshow-container-" + [i]).slick({
                slidesToShow: 3,
                slidesToScroll: 3,
                variableWidth: false,
                autoplay: false,
                controls: false,
                dots: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            });
            $('.our-work-slider-arrows__back').click(function() {
                $(".our-work-slider__slideshow-container").slick("slickPrev");
            })
            $('.our-work-slider-arrows__forward').click(function() {
                $(".our-work-slider__slideshow-container").slick("slickNext");
            })
        }
    }

    let aboutProjectSlideshow = $('.single-market__about-project--slideshow');
    if (aboutProjectSlideshow.length) {
        let singleMarketSlideshow = tns({
            container: '.single-market__about-project--slideshow',
            items: 1,
            slideBy: 1,
            gutter: 40,
            autoplay: false,
            controls: false,
            nav: false,
            autoplayButton: false,
            autoplayButtonOutput: false,
            responsive: {
                768: {
                    items: 1.5,
                },
                1024: {
                    items: 2.5,
                }
            }
        });

        $('.single-market__about-project--slideshow-container .controls .prev').click((el) => {
            singleMarketSlideshow.goTo('prev');
        })
        $('.single-market__about-project--slideshow-container .controls .next').click(() => {
            singleMarketSlideshow.goTo('next');
        })
    }

    let singleMarketTestimonialsEl = $('.single-market__testimonials');
    if (singleMarketTestimonialsEl.length) {
        let singleMarketTestimonials = tns({
            container: '.single-market__testimonials',
            items: 1,
            slideBy: 1,
            // gutter: 40,
            autoplay: false,
            controls: false,
            nav: true,
            navPosition: 'bottom',
            autoplayButton: false,
            autoplayButtonOutput: false,
        });

        $('.single-market__testimonials .controls .prev').click((el) => {
            singleMarketTestimonials.goTo('prev');
        })
        $('.single-market__testimonials .controls .next').click(() => {
            singleMarketTestimonials.goTo('next');
        })
    }

});
