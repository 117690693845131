/* Smooth page scroll to an anchor on the same page.
    ========================================================================== */
    jQuery(function() {
        jQuery('a[href*="#"]:not([href="#"])' && 'a[href*="#"]:not([href="#subscribe"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = jQuery(this.hash);
            target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
            console.log(target);
            if (target.length) {
            jQuery('html, body').animate({
                scrollTop: target.offset().top - 100
            }, 1000);
            return false;
            }
        }
        console.log(target);

        });
    });

    /* Smooth page scroll to an anchor on a different page.
    ========================================================================== */
    jQuery(window).load( function(){
        if( location.hash ){
            var hash = location.hash;
            jQuery('html, body').animate({ scrollTop: jQuery(hash).offset().top -125 }, 1000);
        }
        return false;
    } );
